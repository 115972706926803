import React from 'react';
import getUnixTime from '../utils/getUnixTime';
import Iframe from './Iframe';
import { startOfRamadan, endOfRamadan } from '../constants/importantDates';

const { useEffect, useState } = React;

function Timer({ iftarData, sehriData, currentLocation }) {
	const [currentTime, setCurrentTime] = useState(new Date().getTime());
	const iftarTime = getUnixTime(iftarData.date, iftarData.time);
	const sehriTime = getUnixTime(sehriData.date, sehriData.time);

	const sehriTimeDiff = new Date(sehriTime).getTime() - currentTime;
	const iftarTimeDiff = new Date(iftarTime).getTime() - currentTime;

	let timeBetween;
	if (sehriTimeDiff > 0) {
		timeBetween = new Date(sehriTime).getTime() - currentTime;
	} else if (iftarTimeDiff > 0) {
		timeBetween = new Date(iftarTime).getTime() - currentTime;
	}

	const seconds = Math.floor((timeBetween / 1000) % 60);
	const minutes = Math.floor((timeBetween / 1000 / 60) % 60);
	const hours = Math.floor((timeBetween / (1000 * 60 * 60)) % 24);

	const oneDay = 24 * 60 * 60 * 1000;
	const todaysDate = new Date().setHours(0, 0, 0, 0);

	const isEidDay = endOfRamadan === todaysDate;
	const isMonthOfRamadan =
		todaysDate >= startOfRamadan && todaysDate <= endOfRamadan;

	const diffDays = isMonthOfRamadan
		? Math.round((endOfRamadan - todaysDate) / oneDay)
		: Math.round((startOfRamadan - todaysDate) / oneDay);

	const ramadanDayNumber = isMonthOfRamadan
		? Math.abs(Math.round((startOfRamadan - todaysDate) / oneDay))
		: null;

	useEffect(() => {
		const interval = setInterval(() => {
			setCurrentTime(new Date().getTime());
		}, 1000);

		return () => clearInterval(interval);
	}, []);

	return (
		<>
			<header className="App-header">
				{isEidDay ? (
					<Iframe
						title="eid"
						src="https://giphy.com/embed/3o1MbsqpBthubCto1f"
					/>
				) : (
					<>
						{isMonthOfRamadan ? (
							<Iframe
								title="ramadan"
								src="https://giphy.com/embed/nKA4aQgYPQKHbRA10y"
							/>
						) : (
							<Iframe
								title="passedRamadan"
								src="https://giphy.com/embed/f8Q0nkyNbEUGkfbrMj"
							/>
						)}
					</>
				)}
			</header>
			<div className="prayerTime">
				<h1>
					{new Date().toLocaleDateString(undefined, {
						weekday: 'short',
						year: 'numeric',
						month: 'long',
						day: 'numeric',
					})}
				</h1>
				<h2>
					📍{' '}
					{currentLocation?.city ||
						currentLocation?.state ||
						currentLocation?.country}
				</h2>
				{diffDays - 1 > 0 ? (
					<>
						<h3>
							Days till {isMonthOfRamadan ? 'Eid' : 'Ramadan'} : {diffDays - 1}{' '}
							day
							{diffDays - 1 === 1 ? '' : 's'}
						</h3>
						{isMonthOfRamadan ? <h3>{ramadanDayNumber + 1} Ramadan</h3> : null}
					</>
				) : null}
				{diffDays - 1 === 0 && (
					<>
						<h3>Ramadan Starts Tomorrow!</h3>
					</>
				)}
			</div>
			<div className="timer">
				{isEidDay ? (
					<>
						<h3>No fasting today! Wishing you and your family a happy Eid!</h3>
						<h1>Eid Mubarak!</h1>
					</>
				) : (
					<div>
						{timeBetween === undefined ? (
							<>
								<h3>✨ Timer will start again at midnight ✨</h3>
							</>
						) : (
							<>
								{isMonthOfRamadan ? (
									<h2>
										Time remaining till{' '}
										{sehriTimeDiff > 0 ? 'end of Sehri' : 'Iftar'}:
									</h2>
								) : (
									<h2>
										Time remaining till{' '}
										{sehriTimeDiff > 0 ? 'Sunrise' : 'Sunset'}:
									</h2>
								)}
								{sehriTimeDiff > 0 ? (
									<h4>
										{hours} hrs {minutes < 10 ? `0${minutes}` : minutes} mins{' '}
										{seconds < 10 ? `0${seconds}` : seconds} secs
									</h4>
								) : (
									<h1>
										{hours} hrs {minutes < 10 ? `0${minutes}` : minutes} mins{' '}
										{seconds < 10 ? `0${seconds}` : seconds} secs
									</h1>
								)}
							</>
						)}
					</div>
				)}
			</div>
		</>
	);
}

export default Timer;
